.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spectrumBg{
  position: absolute;
  height: 200px;
  width: 160%;
  left: 0;
  margin: 0;
  background: url(../src/assets/spectrum2.png) 15px 35px no-repeat;
}

.caret.caret-reversed {
  border-bottom: 10px solid #4398F1;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  height: 0;
  vertical-align: top;
  width: 0;
  left:0;
  right:0;
  transform: rotate(180deg);
}

.social-icons {
  display: flex;
}
.icon {
  width: 36px;
  height: 26px;
  background: url("https://yourservicerequest.stayconnected.telstra.com.au/assets/img/social-network-icons.png") no-repeat 0 0 transparent;
  background-size: cover; /* Scale the background image */
  display: inline-block;
  text-indent: -9999px; /* Hide text for accessibility */
}
.facebook {
  background-position: 0 0; /* Position for Facebook icon */
}
.twitter {
  background-position: -50px 0; /* Adjust based on your sprite image */
}
.youtube {
  background-position: -100px 0; /* Adjust based on your sprite image */
}
.gplus {
  background-position: -150px 0; /* Adjust based on your sprite image */
}

footer ul.ul-horizontal {
  height: 25px;
  padding: 0;
}

footer ul.ul-horizontal li.facebook {
  background-position: 0 0;
  cursor: pointer;
}

footer ul.ul-horizontal li.twitter {
  background-position: -50px 0;
}

footer ul.ul-horizontal li.youtube {
  background-position: -100px 0;
}

footer ul.ul-horizontal li.gplus {
  background-position: -150px 0;
}

footer ul.ul-horizontal li {
  float: left;
  list-style-type: none;
  width: 28px;
  height: 26px;
  background: url("https://yourservicerequest.stayconnected.telstra.com.au/assets/img/social-network-icons.png") no-repeat 0 0 transparent;
  margin-right: 5px;
}


li::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}